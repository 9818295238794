<template>
  <div class="chessHand" style="padding-bottom: 20px">
    <div v-title data-title="棋牌送彩"></div>

    <!-- <van-nav-bar
      title="欢乐棋牌"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-ChessHand.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"> <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 我的金额 -->
        <div class="rule-content" style="margin-bottom:20px">
          <div class="rule-content-title2" style="margin-top:0px"></div>
          <table>
            <tr>
              <td
                style="border-top:1px solid #eee;border-bottom:1px solid #eee;background: linear-gradient(90deg, #3D73FA, #63B3FC);;color: #fff;width: 150px;">
                更新时间
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.date }}
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                瓦力棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.wl_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                幸运棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.xy_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                贝宝棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.kx_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                OB棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.ob_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);;color: #fff;border-bottom:1px solid #eee">
                开元棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.ky_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;border-bottom:1px solid #eee">
                乐游棋牌
              </td>
              <td style="border-top:1px solid #eee;border-bottom:1px solid #eee;background-color: var(--w-bgcolor);">
                {{ datalist.leg_beat }} 元
              </td>
            </tr>
            <tr>
              <td style="background: linear-gradient(90deg, #3D73FA, #63B3FC);color: #fff;">
                棋牌总流水
              </td>
              <td style="background-color: var(--w-bgcolor);">{{ datalist.beat }} 元</td>
            </tr>
          </table>
        </div>

        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">瓦力棋牌、幸运棋牌、贝宝棋牌、OB棋牌、KY棋牌、乐游棋牌</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">长期</span>
            </div>
            <div class="title-item">
              <p>活动条件:</p>
              <span style="color:var(--li-colortext);">流水要求</span>
            </div>

            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">在活动开启期间内，前往指定棋牌场馆进行游戏，流水满足申请条件后即可申请彩金，工作人员审核后系统自动发放。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead style="
                          border-top-left-radius: 10px;
                          border-top-right-radius: 10px;
                        ">
              <tr style="
                        background: linear-gradient(90deg, #4581fa, #63b3fc)
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      ">
                <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                              border-top-left-radius: 10px;
                              border-top: 1px solid #fff;
                              border-left: 1px solid #fff;
                            ">
                  有效投注额
                </th>
                <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                              border-top: 1px solid #fff;

                            ">
                  日常彩金
                </th>
                <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                              border-top: 1px solid #fff;
                              
                            ">
                  周末彩金
                </th>

                <th
                  style="color: #fff; border-top-right-radius: 10px; border-top: 1px solid #fff; border-right: 1px solid #fff;">
                  流水倍数
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.config" :key="index">
                <td>≥{{ item.beat }}</td>
                <td>{{ item.day_bouns }}</td>
                <td>{{ item.week_bonus }}</td>
                <td v-if="index == 0" :rowspan="datalist.config.length">{{datalist.wlimit}}倍</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">立即申请</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                本活动游戏场馆范围仅限于瓦力棋牌、幸运棋牌、贝宝棋牌、OB棋牌、KY棋牌、乐游棋牌。
              </li>
              <li>
                <span>2.</span>
                每周的周一至周五领取日常彩金，周六至周日领取周末彩金。
              </li>
              <li>
                <span>3.</span>
                本活动流水于次日17:00后更新显示，申请时间是每天17:00到晚上23:59，需要玩家本人申请，每天都可以领取一次。
              </li>
              <li>
                <span>4.</span>
                每位会员在活动期间内每日只能申请一次，申请后统计当日的棋牌场馆(指定游戏场馆内的流水全部相加)。
              </li>
              <li>
                <span>5.</span>
                流水统计会存在20分钟左右缓存时间，如会员发现申请时流水不正确可等待片刻后重试。
              </li>
              <li>
                <span>6.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>7.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>8.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data () {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: ''

    }
  },
  onShow () {
    console.log('tk1111', this.is_token)
  },

  mounted () {
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')

  },

  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },

    getdata () {
      var _this = this
      var data = '?type=ChessHand'
      getdata(data).then((res) => {
        this.datalist = res.message
      })
    },
    changeisshow () {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay () {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'ChessHand'
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // 申请
          uni.postMessage({
            data: {
              urlApply: 'ChessHandApply'
            }
          })
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //       actobject: {
          //         act: 'ChessHand'
          //       }
          //   }
          // })
        }
      })
    }
  }
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    >div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      >div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
